<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row>
                <v-overlay :value="overlayDesgarga" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{frase}}</p>
                    </div>
                </v-overlay>
                <v-col lg="12">
                    <data-table
                        :url="url"
                        :columns="columns"
                        :tableName="'Reporte de asistencia'"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10, 25, 50, 100]"
                        @datosTabla="datosTabla"
                    >
                        <template  v-if="rol == 'admin'|| rol == 'admin-empresa' || rol == 'admin-sucursal' || rol == 'admin-depto'" slot="actionButtonsLeft">
                            <div style="padding-right: 10px;">
                                <v-speed-dial
                                    v-model="btnDoc"
                                    direction="bottom"
                                    open-on-hover
                                    transition="scale-transition"
                                    class="btn-download-cloud2"
                                >
                                    <template v-slot:activator>
                                        <v-btn
                                            v-model="btnDoc"
                                            color="#FFFFFF"
                                            fab
                                            class="btn-download-cloud2"
                                            elevation="3"
                                        >
                                            <v-icon v-if="btnDoc" color="#96999A">mdi-close</v-icon>
                                            <v-icon v-else color="#96999A" v-text="'$get_app_outline'"></v-icon>
                                        </v-btn>
                                    </template>
                                    <v-btn
                                        fab
                                        small
                                        color="#FFFFFF"
                                        @click="exportar('pdf')"
                                    >
                                        <v-icon color="red">mdi-file-pdf</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        small
                                        color="#FFFFFF"
                                        @click="exportar('xlsx')"
                                    >
                                        <v-icon color="green">mdi-file-excel</v-icon>
                                    </v-btn>
                                </v-speed-dial>
                            </div>
                        </template>

                        <template slot="filters">
                            
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio_fil"
                                    label="Fecha inicial"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicial"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final_fil"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>

                            <template v-if="rol == 'root'">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Empresa"
                                        persistent-hint
                                        v-model="empresa_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="departamentos"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Departamento"
                                        persistent-hint
                                        v-model="departamento_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <v-col v-if="rol != 'empleado'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="nombre_completo_empleados"
                                    item-text="nombre_completo"
                                    item-value="id"
                                    label="Empleado"
                                    persistent-hint
                                    v-model="empleado_fil"
                                >
                                </v-autocomplete>
                            </v-col>
                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td style="width:120px !important; padding-right:4px !important;">
                                    <div class="tblPrincipal">{{ item.clave }}</div>
                                </td>

                                <td class="px-1">
                                    <div style="max-width:30rem"> 
                                        <v-list  color="transparent" class="py-0 my-0">
                                            <v-list-item class="py-0 my-0 px-0">
                                                <v-list-item-avatar  class="py-0 my-0">
                                                    <v-img  :src="urlAvatar+item.foto"></v-img>
                                                </v-list-item-avatar>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-list-item-content v-on="on" class="py-0 my-0">
                                                            <v-list-item-title class="tblPrincipal text-wrap">{{ item.nombre_completo }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                    <span>{{ item.nombre_completo }}</span>
                                                 </v-tooltip>  
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td class="px-1" style="min-width:220px; max-width:221px;">
                                    <div class="">
                                        <template 
                                            v-if="
                                                item.incidencia == null || 
                                                item.incidencia == undefined || 
                                                item.incidencia == ''
                                                "
                                        > 
                                        </template>
                                        <v-tooltip bottom v-else>
                                            <template v-slot:activator="{ on }">
                                                <v-chip
                                                    class="vChip"
                                                    :color="getColor(item.incidencia)"
                                                    :text-color="getColorTexto(item.incidencia)"
                                                    v-on="on"
                                                    outlined
                                                >
                                                    {{item.incidencia}}
                                                </v-chip>
                                            </template>
                                            <span>{{ item.incidencia }}</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                                <td class="tblPrincipal">
                                    <div class="tblPrincipal" style="margin-top:10px;">
                                        {{ dateFormat(item.fecha) }}
                                    </div>
                                </td>

                                <td
                                    class="text-center"
                                    :class="{
                                        error_checada: item.retardo == 'SI',
                                    }"
                                >
                                    <div class="">
                                        {{ item.hora_entrada }}
                                    </div>
                                </td>
                                <td
                                    class="text-center"
                                    :class="{
                                        error_checada: item.anticipado == 'SI',
                                    }"
                                >
                                    <div class="text-center">
                                        {{ item.hora_salida }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import empleadosApi from "../api/empleados";
import clienteApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "../api/sucursales";
import departamentoApi from "../api/departamentos";
import DataTable from "@/components/datatable/Datatable.vue";
import urlImg from "../plugins/Urlimagen";
import PerfectScrollbar from "perfect-scrollbar";
import Notify from "@/plugins/notify";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

export default {
    components: {
        "data-table": DataTable,
        VuetifyDatePicker,
    },
    data() {
        return {
            url: "empleados/find-checked",
            columns: [
                {
                    label: "Clave",
                    name: "clave",
                    filterable: true,
                },
                {
                    label: "Empleado",
                    name: "empleado",
                    filterable: true,
                },
                {
                    label: "Incidencia",
                    name: "incidencia",
                    filterable: true,
                },
                {
                    label: "Fecha",
                    name: "fecha",
                    filterable: true,
                },
                {
                    label: "Hora de entrada",
                    name: "entrada",
                    filterable: true,
                    align:'center'
                },
                {
                    label: "Hora de salida",
                    name: "salida",
                    filterable: true,
                    align:'center'
                },
            ],
            filters: {
                rol_name            : null,
                usuario_id          : null,
                activo              : true,
                paginate            : true,
                include_padres      : true,
                include_horario     : true,
                cliente_id          : null,
                empresa_id          : null,
                sucursal_id         : null,
                departamento_id     : null,
                empleado_id         : null,
                min_fecha_checada   : null,
                max_fecha_checada   : null,
                order_by            : 'fecha',
            },
            loading     : true,
            dialog      : false,
            search      : "",
            datosLogin  : "",
            titulo_modal: "Busqueda avanzada",
            complex     : {
                selected: [],
                headers: [
                { text: "Clave", value: "Clave" },
                { text: "Empleado", value: "nombre" },
                { text: "Incidencia", value: "incidencia" },
                { text: "Fecha", value: "fecha" },
                { text: "Hora Entrada", value: "hora_entrada" },
                { text: "Salida", value: "hora_salida" },
                ],
                items: [],
            },
            menu_final          : false,
            menu_inicio         : false,
            fecha_final         : null,
            fecha_inicio        : null,
            empleados           : [],
            empleado            : null,
            clave               : null,
            empleado_id         : null,

            inicio              : "",
            final               : "",
            finaliza_carga      : false,
            rol                 : null,
            parametros_actuales : "",
            empleado_data       : null,
            cliente_value       : null,
            empresa_value       : null,
            sucursal_value      : null,
            departamento_value  : null,
            clientes            : [],
            empresas            : [],
            sucursales          : [],
            departamentos       : [],
            accion              : "",
            fecha_inicio_fil    : null,
            fecha_final_fil     : null,
            empleado_fil        : null,
            nombre_completo_empleados: [],
            urlAvatar           : null,
            btnDoc              : false,
            overlayDesgarga     : false,
            frase               :'Descargando reporte',
            numRegistros        : 0,
        };
    },
    watch: {
        cliente_value: function(val, oldVal){
            if(val != null){
                this.cambiaCliente(val);
                if(val != oldVal && oldVal != null){
                    this.empresa_value = null;
                    this.sucursal_value = null;
                    this.sucursales = [];
                }
            }
            this.getEmpleados();
        },
        empresa_value: function(val, oldVal) {
            if(val != null){
                this.cambiaEmpresa(val);
                if(val != oldVal && oldVal != null ){
                    this.sucursal_value = null;
                }
            }
            this.getEmpleados();
        },
        sucursal_value: function(val, oldVal){
            if(val != null){
                this.getDepartamentos(val);
            }
        },
        departamento_value: function(val, oldVal){
            if(val != null){
                this.getEmpleados();
            }
        }
    },
    methods: {
        listar(accion = null) {
            this.loading = true;
            this.datosLogin = this.$session.get("usuario");
            this.empleado_data = this.$session.get("empleado");
            this.rol = this.datosLogin.rol.name;
            this.urlAvatar = urlImg.getURL();
            this.filters.rol_name = this.datosLogin.rol.name;
            this.filters.usuario_id = this.datosLogin.id;

            let fecha = new Date();

            let mes = String(fecha.getMonth() + 1);
            let dia = String(fecha.getDate());

            if (mes.length < 2) {
                mes = "0" + mes;
            }
            if (dia.length < 2) {
                dia = "0" + dia;
            }

            this.inicio = fecha.getFullYear() + "-" + mes + "-01";
            this.fecha_inicio = fecha.getFullYear() + "-" + mes + "-01";

            this.final = fecha.getFullYear() + "-" + mes + "-" + dia + " 23:59:00";
            this.fecha_final = fecha.getFullYear() + "-" + mes + "-" + dia + " 23:59:00";

            if (accion != null) {
                sucursal = this.sucursal_value;
                this.cerrarModal();
            }
            if (this.rol == "admin") {
                this.filters.cliente_id = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.filters.empresa_id = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.filters.sucursal_id = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                this.filters.departamento_id = this.datosLogin.departamento_id;
            } else if (this.rol == "empleado") {
                this.filters.empleado_id = this.datosLogin.empleado_id;
            }
            if (this.fecha_inicio != null) {
                this.filters.min_fecha_checada = this.fecha_inicio;
            }
            if (this.fecha_final != null) {
                this.filters.max_fecha_checada = this.fecha_final;
            }
        },
        setFilters() {
            let fecha_inicio = this.filters.min_fecha_checada;
            let fecha_final = this.filters.max_fecha_checada;
            let empleado = this.empleado_fil;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let sucursal = this.sucursal_value;
            let departamento = this.departamento_value;

            if (this.fecha_inicio_fil != null) {
                fecha_inicio = this.fecha_inicio_fil;
            }
            if (this.fecha_final_fil != null) {
                fecha_final = this.fecha_final_fil;
            }

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                departamento = this.datosLogin.departamento_id;
            } else if (this.rol == "empleado") {
                empleado = this.datosLogin.empleado_id;
            }

            let filterParams = {
                cliente_id      : cliente,
                empresa_id      : empresa,
                sucursal_id     : sucursal,
                departamento_id : departamento,
                empleado_id     : empleado,
                min_fecha_checada: fecha_inicio,
                max_fecha_checada: fecha_final,
            };

            let defaultParams = {
                activo: true,
                include_padres: true,
                include_horario: true,
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id,
            };

            if (filterParams != null) {
                Object.assign(defaultParams, filterParams);
            }
            this.filters = defaultParams;

            this.fecha_inicio_fil = null;
            this.fecha_final_fil = null;
            this.cliente_value = null;
            this.empresa_value =  null;
            this.sucursal_value = null;
            this.departamento_value = null;
        },
        async loadModalData() {
            this.empleado_fil = null;
            let cliente = null;
            let empresa = null;
            let sucursal = null;
            let depto = null;
            await this.resetvalue();

            this.filters.min_fecha_checada = this.inicio;
            this.filters.max_fecha_checada = this.final;

            this.fecha_inicio_fil = null;
            this.fecha_final_fil = null;
            if(this.$refs.FechaInicial != undefined || this.$refs.FechaInicial != null){
                this.$refs.FechaInicial.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            

            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                cliente = this.datosLogin.cliente_id;
            }
            if (this.rol == "admin-empresa") {
                
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                empresa = this.datosLogin.empresa_id;
            }
            if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                sucursal = this.datosLogin.sucursal_id;
            }
            if (this.rol == "admin-depto") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.departamento_value = this.datosLogin.departamento_id;
            }
        },
        async getEmpleados(){

            let param = {
                activo          : true,
                paginate        : false,
                cliente_id      : this.cliente_value,
                empresa_id      : this.empresa_value,
                sucursal_id     : this.sucursal_value,
                departamento_id : this.departamento_value,
                rol_name        : this.datosLogin.rol.name,
                usuario_id      : this.datosLogin.id,
            };
            empleadosApi.getEmpleados(param).then((response) => {
                this.nombre_completo_empleados = response.data;
            })
            .catch((err) => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async getClientes(){
            let parametros = { activo: true, paginate: false};
            await clienteApi.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.log(err);
                this.respuesta_error(err);
            });
        },
        async cambiaCliente(cliente_id, accion = null) {
            let parametros = { activo: true, paginate: false, cliente_id: cliente_id };
            await empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                console.log(err);
                this.respuesta_error(err);
            });
        },
        async cambiaEmpresa(empresa, accion = null) {
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            await sucursalApi.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
            })
            .catch((err) => {
                console.log(err);
                this.respuesta_error(err);
            });
        },
        async getDepartamentos(sucursal, accion = null) {
            let parametros = { activo: true, paginate: false, sucursal_id: sucursal };
            await departamentoApi.getDepartamentos(parametros).then((response) => {
                this.departamentos = response.data;
            })
            .catch((err) => {
                console.log(err);
                this.respuesta_error(err);
            });
        },
        async exportar(tipo_doc = null) {
            if(this.numRegistros == 0){
                Notify.ErrorToast("No hay registros para realizar la descarga del reporte.");
                return;
            }
            let self = this;
            let url = "reportes/asistencia";
            this.overlayDesgarga = true;
            const FileDownload = require("js-file-download");
            var today = new Date();

            let nombre;
            if(tipo_doc == 'xlsx'){
                nombre = "asistencias_" +
                today.getDate() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getFullYear() +
                ".xlsx";
            }
            else{
                nombre = "asistencias_" +
                today.getDate() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getFullYear() +
                ".pdf";
            }

            let param = {...this.filters};
            param.paginate = false;
            param.tipo = tipo_doc;
            const object1 = param;
            try {
                await Vue.axios({
                    method: "GET",
                    responseType: "blob",
                    url: url,
                    params: object1,
                }).then((response, status, xhr) => {
                    this.overlayDesgarga = false;
                    FileDownload(response.data, nombre);
                })
                .catch(async error =>{
                    this.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
            } catch (e) {
                var message = null;
                this.overlayDesgarga = false;
            }
        },
        resetvalue() {
            this.finaliza_carga = false;
            this.empleado       = null;
            this.clave          = null;
            this.fecha_inicio   = null;
            this.fecha_final    = null;
            this.empleado_id    = null;
            this.cliente_value  = null;
            this.empresa_value  = null;
            this.sucursal_value = null;
            this.departamento_value = null;
            this.clientes       = [];
            this.empresas       = [];
            this.sucursales     = [];
            this.departamentos  = [];
            this.nombre_completo_empleados = [];
        },
        cerrarModal() {
            this.dialog = false;
        },
        respuesta_error(err) {
            console.log(err);
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },
        getColor(incidencia) {
            switch (incidencia) {
                case "Salida anticipada":
                    return "#F03738";
                    break;
                case "Falta sin goce":
                    return "#F03738";
                    break;
                case "Retardo":
                    return "#F03738";
                    break;
                case "Sin registro de salida":
                    return "#F03738";
                    break;
                case "No hay registro":
                    return "rgba(95,92,97,0.1)";
                    break;
                case "Descanso":
                    return "rgba(130,130,130,0.2)";
                    break;
                case undefined:
                    return "";
                    break;
                default:
                    return "#20e06e";
                    break;
            }
        },
        getColorTexto(incidencia) {
            switch (incidencia) {
                case "Descanso":
                    return "#828282";
                    break;
                case "Salida anticipada":
                    return "#FA5471";
                    break;
                case "Falta sin goce":
                    return "#FA5471";
                    break;
                case "Retardo":
                    return "#FA5471";
                    break;
                case "Sin registro de salida":
                    return "#F03738";
                    break;
                case "No hay registro":
                    return "#5F5C61";
                    break;
                case undefined:
                    return "";
                    break;
                default:
                    return "#20E06E";
                    break;
            }
        },
        init() {
            const elementScroll = document.querySelector("#scroll");
            if (elementScroll) {
                const ps = new PerfectScrollbar(elementScroll, {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20,
                    suppressScrollY: true,
                });
            }
            $(".ps__thumb-x").css("position", "fixed");
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        datosTabla(datos){
            this.numRegistros = datos.data.length;
        },
    },
    mounted() {
        this.init();
        let  tableScroll = document.querySelector('#tableScroll');

        if(tableScroll){
            document.querySelector('#tableScroll thead tr th:nth-child(1)').style.paddingRight  = "4px";
            document.querySelector('#tableScroll thead tr th:nth-child(1)').style.width         = "120px";
            document.querySelector('#tableScroll thead tr th:nth-child(2)').style.paddingRight  = "4px";
            document.querySelector('#tableScroll thead tr th:nth-child(2)').style.paddingLeft   = "4px";
            document.querySelector('#tableScroll thead tr th:nth-child(3)').style.minWidth      = "220px";
            document.querySelector('#tableScroll thead tr th:nth-child(3)').style.paddingRight  = "4px";
            document.querySelector('#tableScroll thead tr th:nth-child(3)').style.paddingLeft   = "4px";
        }
    },
    created() {
        this.listar();
    },
    updated() { 
        let  tableScroll = document.querySelector('#tableScroll');

        if(tableScroll){
            document.querySelector('#tableScroll thead tr th:nth-child(1)').style.paddingRight  = "4px";
            document.querySelector('#tableScroll thead tr th:nth-child(1)').style.width         = "120px";
            document.querySelector('#tableScroll thead tr th:nth-child(2)').style.paddingRight  = "4px";
            document.querySelector('#tableScroll thead tr th:nth-child(2)').style.paddingLeft   = "4px";
            document.querySelector('#tableScroll thead tr th:nth-child(3)').style.minWidth      = "220px";
            document.querySelector('#tableScroll thead tr th:nth-child(3)').style.paddingRight  = "4px";
            document.querySelector('#tableScroll thead tr th:nth-child(3)').style.paddingLeft   = "4px";
        }
        
    },
};
</script>
<style scoped>
    .error_checada {
        color: red !important;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.34px;
        line-height: 17px;
        text-align: left;
        /* padding-left: 5%; */
    }
    .verde {
        color: #0eb60e !important;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.34px;
        line-height: 17px;
        text-align: left;
        /* padding-left: 5%; */
    }
    .vChip {
        background-color: transparent;
        min-width: 165px;
        justify-content: center;
    }
    .vChip .v-chip .v-chip__content {
        height: 20px;
    }
    .trIncidencias {
        width: 200px;
    }
    .tipoLetra {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 20px;
        text-align: center;
    }
    .v-chip .v-chip__content {
        font-family: Montserrat !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 20px;
        text-align: center;
    }
</style>
