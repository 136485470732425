import { render, staticRenderFns } from "./Registros.vue?vue&type=template&id=5c22be25&scoped=true"
import script from "./Registros.vue?vue&type=script&lang=js"
export * from "./Registros.vue?vue&type=script&lang=js"
import style0 from "./Registros.vue?vue&type=style&index=0&id=5c22be25&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c22be25",
  null
  
)

export default component.exports